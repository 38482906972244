<template>
  <div class="request-author">
    <Popup
      class="popup"
      custom-class="gray-header"
      modal-id="request-author"
      :on-close="onModalClose"
      size="lg"
      :toggle-status="toggleStatus"
    >
      <template v-slot:header> {{ labels.request_author_to_module }} </template>
      <template v-slot:body>
        <div v-if="modulePageData.tabsContentCanBeShown" class="row h-100">
          <div
            class="request-author__inputs h-100 mb-4 mb-md-0 col-12 col-md-5"
          >
            <FormulateForm
              class="request-form d-flex flex-column h-100"
              v-model="formData"
            >
              <FormulateInput
                type="text"
                name="title"
                :label="labels.title"
                error-behavior="blur"
                validation="required"
                :validation-messages="{
                  required: `${labels.title} ${labels.required.toLowerCase()}`,
                }"
                :disabled="inputsDisabled()"
                :value="requestedAuthorsMessage.title || ''"
              />
              <FormulateInput
                type="textarea"
                name="description"
                :label="labels.description"
                error-behavior="blur"
                validation="required"
                :validation-messages="{
                  required: `${
                    labels.description
                  } ${labels.required.toLowerCase()}`,
                }"
                :disabled="inputsDisabled()"
                :value="requestedAuthorsMessage.text || ''"
              />
              <Button
                class="mt-3"
                :label="labels.send"
                variant="btn-purple"
                type="submit"
                @click="requestSend"
                :disabled="inputsDisabled()"
              />
            </FormulateForm>
          </div>
          <div class="request-author__table h-100 col-12 col-md-7">
            <TableOverview
              kind="user"
              :settings="settings"
              :entities="agreedUsers"
            />
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
import Button from "@/components/Buttons/Button.vue";
import Popup from "@/components/Popups/Popup";
const colors = require("/colors.config.json");
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "CreateModule",
  components: {
    Popup,
    TableOverview,
    Button,
  },
  props: {
    requestedAuthorsMessage: {
      type: [Object, Boolean],
      required: true,
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggleStatus: false,
      formData: {
        title: "",
        description: "",
      },
      suggestedAuthors: new Set(),
    };
  },
  computed: {
    ...mapState("helper", {
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("_module", {
      singleData: "singleData",
    }),

    ...mapGetters("helper", ["labels"]),

    isAuthor() {
      let flag = false;
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes("author")) {
        this.singleData.authors?.forEach((author) => {
          if (author._id === this.user_id) {
            flag = true;
          }
        });
      }
      return flag;
    },
    headerUsers() {
      console.log(this.agreedUsers.length);
      if (this.agreedUsers.length > 0)
        return this.labels.content_users_that_responded_to_your_request;
      else return this.labels.no_user_responded_to_your_request;
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.headerUsers,
            headerColor: `${colors.gray_text}`,
            key: "username",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 60%",
            whiteSpace: "nowrap",
          },
        ],
        sortable: false,
        minTableWidth: "100%",
        actionsWidth: "0 0 40%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.add_to_module,
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.addAuthorToModule(e),
          },
        ],
      };
    },
    agreedUsers() {
      if (
        typeof this.requestedAuthorsMessage === "object" &&
        this.requestedAuthorsMessage.payload?.users
      ) {
        if (
          !this.singleData ||
          !this.singleData.authors ||
          !this.singleData.authors.length
        )
          return this.requestedAuthorsMessage.payload.users;

        let agreedAuthors = [];

        this.requestedAuthorsMessage.payload.users.forEach((user) => {
          if (!this.singleData.authors.some((e) => e._id === user._id)) {
            agreedAuthors.push(user);
          }
        });

        return agreedAuthors;
      }

      return [];
    },
  },
  methods: {
    onModalClose() {
      this.$router.push({ name: "module-authors" });
      this.toggleStatus = false;
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("_module", {
      requestAuthor: "requestAuthor",
      toggleAuthor: "toggleAuthor",
    }),
    ...mapMutations("_module", {
      updateSingleModule: "updateSingleModule",
    }),
    requestSend() {
      let data = {
        id: this.singleData._id,
        title: this.formData.title,
        text: this.formData.description,
        suggestedAuthors: [...this.suggestedAuthors],
      };

      this.requestAuthor(data)
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["saved"],
              labels: this.labels,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
      this.toggleStatus = false;
    },
    inputsDisabled() {
      if (this.requestedAuthorsMessage === false) {
        return false;
      }

      return true;
    },
    addAuthorToModule(user) {
      this.toggleAuthor({
        id: this.singleData._id,
        author_id: user._id,
        action: true,
      })
        .then((res) => {
          if (res.success) {
            let updatedModule = { ...this.singleData };

            updatedModule.authors.push({
              _id: user._id,
              username: user.username,
            });

            this.updateSingleModule(updatedModule);

            this.addNotification({
              variant: "success",
              msg: ["author_has_been_added"],
              labels: this.labels,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    admin_roles() {
      if (!this.isAuthor) {
        this.$router.push({ name: "module-overview" });
      }
    },
  },
  mounted() {
    this.toggleStatus = true;
  },
  beforeDestroy() {
    this.toggleStatus = false;
  },
};
</script>

<style lang="scss" scoped>
.request-author {
  &__inputs {
    border-right: 1px solid $light_gray;
  }
}

::v-deep {
  .modal-body {
    @media (min-width: 768px) {
      overflow-y: hidden;
      height: 600px;
    }

    button:disabled,
    button:disabled:hover {
      opacity: 0.5;
      box-shadow: 0px 2px 11px -1px #00000038;
      filter: unset;
    }
  }
  .formulate-input {
    margin-bottom: 5px;

    & input:disabled,
    & textarea:disabled {
      opacity: 0.5;
    }

    &[data-classification="textarea"] {
      flex: 1;
      display: flex;
      flex-direction: column;

      & textarea {
        resize: none;
        height: 100%;
      }

      .formulate-input-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        div[data-type="textarea"] {
          flex: 1 1 auto;
        }
      }
    }

    &-wrapper,
    &-element {
      max-width: 100%;
    }

    &-wrapper label {
      font-family: "Oswald", sans-serif;
    }
  }
  .overview {
    height: 100%;
    &__header {
      margin: 0;
    }
  }
  .base-card {
    &:not(.none) {
      box-shadow: unset;
      height: 100%;
      &:hover {
        box-shadow: unset;
      }
    }
    .table-card__thead {
      &__btn {
        width: 0 !important;
        display: none;
      }
      &__column {
        width: 100% !important;
      }
      gap: 0;
      padding-top: 0;
    }
    .table-card-row.last {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .modal-footer {
    display: none;
  }
  .table-card {
    &__tbody {
      height: calc(100% - 40px);
      overflow-y: auto;
    }
  }
}

.description-title {
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>
